
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@use '@bt-branding/colors';

.item-try-again {
  margin-bottom: 0;
  color: colors.$bt-danger;
  font-size: 14px;
  line-height: 15px;
  margin-top: 10px;
  display: none;

  svg {
	padding-right: 6px;
	color: colors.$bt-danger;
  }
}
