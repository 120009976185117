
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@use '../../../style/skeletonGrids.scss';
@use '../../../style/grids.scss';
@import '@bt-branding/colors';

.vertical-list{
  .presenter-span,
  .item-meta{
    width: 100%;
    height: 41px;
  }
}

