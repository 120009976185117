
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@use '../../../style/grids.scss';


.bt-card-hide {
	display: none;
}
