
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@use '../../style/grids.scss';

.buttons-position {
	display: block;
	position: relative;
	z-index: 100;
	min-height: 40px;
}