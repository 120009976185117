
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../style/buttons.scss';
@import '../../style/globalColorVars.scss';

.watch-later-remove-button {
	background-color: $bt-white;
	border:none;

	&:hover, &:active {
		background-color: $bt-white;
	}
}